import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import IntegerField from '@/shared/fields/integer-field';

const field = (name) => i18n.t(`ARCHIVE.WORKSPACE.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ARCHIVE.WORKSPACE.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    name: new StringField(
        'name',
        field('name'),
        { required: true},
        { placeholder: placeholder('name')},
    ),
    description: new StringField(
        'description',
        field('description'),
        { required: false,},
        { placeholder: placeholder('description')},
    ),
    entityType: new StringField(
        'entityType',
        field('entity_type'),
        {},
        { placeholder: placeholder('entity_type')},
    ),
    createdDate: new DateField(
        'createdDate',
        field('created_date'),
    ),
    dimension: new StringField(
        'dimension',
        field('dimension'),
    ),
    foldersNumber: new IntegerField(
        'nrFoldere',
        field('folders_number'),
    ),
};

export class WorkspaceModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
