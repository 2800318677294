<template>
    <st-modal
        id="st-modal-workspace"
        hide-header-delimiter
        hide-footer-delimiter
        size="md"
        hideable
        ref="modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
        </template>
        <template #body>
            <manage-workspace-form
                ref="manage-workspace-form"
                :selectedWorkspace="selectedWorkspace"
                :editMode="editMode"
                @cancelForm="hide"
                @submit="submit"
            >
            </manage-workspace-form>
        </template>
        <template #footer>
            <div class="d-flex justify-content-between w-100">
                <st-button
                    variant="link"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="hide"
                > {{ $t('GENERAL.BUTTON.CANCEL') }}
                </st-button>
                <st-button
                    variant="primary"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="manageWorkspace"
                    :spinner="loading['archive/workspacesForm/create']"
                    :disabled="loading['archive/workspacesForm/create']"
                >
                    <span>{{buttonText}}</span>
                </st-button>
            </div>
        </template>
    </st-modal>
</template>

<script>
import ManageWorkspaceForm from './ManageWorkspaceForm.vue';
import {  mapGetters } from 'vuex';
export default {
    name: 'ManageWorkspaceModal',
    components: {
        ManageWorkspaceForm
    },
    props: {
        selectedWorkspace: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.UPDATE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('ARCHIVE.WORKSPACE.FORM.EDIT_TITLE') :
                this.$t('ARCHIVE.WORKSPACE.FORM.ADD_TITLE');
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manageWorkspace() {
            this.$refs['manage-workspace-form'].manageWorkspace();
        },
        submit() {
            this.$emit('updateWorkspaces');
        }
    }
};
</script>
