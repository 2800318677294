<template>
    <form class="form"
        novalidate="novalidate"
        id="st_modal_workspace_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
             <label>
                {{ fields.name.label }} *
            </label>
            <st-input-text
                v-model="model[fields.name.name]"
                :ref="fields.name.name"
                :name="fields.name.name"
            />
        </div>
        <div class="form-group">
            <label>
                {{ fields.description.label }}
            </label>
            <b-form-textarea
                v-model="model[fields.description.name]"
                class="st-form-textarea"
                rows="5"
                no-resize
                :ref="fields.description.name"
                :name="fields.description.name"
            >
            </b-form-textarea>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { FormSchema } from '@/shared/form/form-schema';
import { WorkspaceModel } from '@/modules/archive/models/workspace-model';

const { fields } = WorkspaceModel;
const formSchema = new FormSchema([
    fields.name,
    fields.description,
])

import Message from '@/shared/message/message';
import { mapActions } from 'vuex';
export default {
    name: 'ManageWorkspaceForm',
    props: {
        selectedWorkspace: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            fields,
            model: {},
        };
    },
    created() {
        this.model = formSchema.initialValues(this.selectedWorkspace);
    },
    mounted() {
        this.fv = createFormValidation('st_modal_workspace_form', this.rules);
    },
    methods: {
        ...mapActions({
            addWorkspace: 'archive/workspacesForm/create',
            updateWorkspace: 'archive/workspacesForm/update',
        }),
        hide() {
            this.$emit('cancelForm');
        },
        manageWorkspace() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.editMode) {
                        this.onUpdateWorkspace();
                    } else {
                        this.onAddWorkspace();
                    }
                }
            });
        },
        onAddWorkspace() {
            const cast = formSchema.cast(this.model);
            const payload = {
                ...cast,
                permissions: [{
                    'permission': '1111',
                        'entityId': '30',
                        'entityType': 3
                }]
            }

            this.addWorkspace(payload)
            .then(() => {
                this.$emit('submit');
                this.hide();
            })
        },
        onUpdateWorkspace() {
            const cast = formSchema.cast(this.model);
            const payload = {
                ...cast,
                realName: this.selectedWorkspace.realName
            }
            this.updateWorkspace(payload)
            .then(() => {
                this.$emit('submit');
                this.hide();
            })
        }
    }
};
</script>
