<template>
    <st-page :title="$t('ARCHIVE.MENU.DOCUMENTS')">
        <template #toolbar>
            <st-button
                v-if="false"
                v-can:admin="'archive'"
                variant="secondary"
                :callback="addWorkspace"
            >
                <i class="fa fa-plus"></i>
                {{ $t('ARCHIVE.BUTTONS.ADD_WORKSPACE') }}
            </st-button>
        </template>
        <workspace-list-filter ref="listWorkspacesFilter">
            <workspace-list-table
                @editWorkspace="editWorkspace"
                @updateWorkspaces="updateWorkspaces"
            />
        </workspace-list-filter>
        <manage-workspace
            ref="manage-workspace"
            @updateWorkspaces="updateWorkspaces"
            :selectedWorkspace="selectedWorkspace"
            :edit-mode="editMode"
        />
    </st-page>
</template>

<script>
    import WorkspaceListFilter from '../components/workspaces/WorkspaceListFilter';
    import WorkspaceListTable from '../components/workspaces/WorkspaceListTable';
    import ManageWorkspace from '../components/workspaces/ManageWorkspaceModal';
    export default {
        name: 'ArchiveWorkspaces',
        components: {
            ManageWorkspace,
            WorkspaceListFilter,
            WorkspaceListTable,
        },
        data() {
            return {
                selectedWorkspace: {},
                editMode: false,
            };
        },
        methods: {
            showModal() {
                this.$refs['manage-workspace'].show();
            },
            addWorkspace() {
                this.editMode = false;
                this.selectedWorkspace = {};
                this.showModal();
            },
            updateWorkspaces() {
                this.$refs.listWorkspacesFilter.refresh();
            },
            editWorkspace(data) {
                this.editMode = true;
                this.selectedWorkspace = data.item;
                this.showModal();
            }
        }
    }
</script>
