<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :modelPresenter="presenter"
            :loading="isLoading"
            :firstColumnIcons="firstColumnIcons"
            :serverSideSorting="true"
            @edit="onEdit"
            @delete="onDelete"
            stateModule="archive/workspaces"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
import {  mapGetters, mapActions} from 'vuex';
import { WorkspaceModel } from '@/modules/archive/models/workspace-model';

const { fields } = WorkspaceModel;

export default {
    name: 'WorkspaceListTable',
    data() {
        return {
            presenter: WorkspaceModel.presenter,
            fields: [
                fields.name.extendField({ bold: true, sortable: true }),
                fields.entityType.extendField({ sortable: true }),
                fields.createdDate.extendField({ sortable: true }),
                fields.dimension.extendField({ sortable: true }),
                fields.foldersNumber.extendField({ sortable: true }),
            ],
            firstColumnIcons: {
                name: 'home',
                customIcon: false,
                type: 'primary'
            },
        };
    },
    computed: {
        ...mapGetters({
            rows: 'archive/workspaces/rows',
            loading: 'shared/loading',
            // archivePermissions: 'archive/archivePermissions'
        }),
        isLoading () {
            return this.loading['archive/getWokspaces'] ;
        },
        // TBD: Action are commented util new decisions are made regarding this
        // actions() {
        //     return [
        //         {
        //             name: 'edit',
        //             icon: 'edit',
        //             tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
        //             customIcon: false,
        //             type: 'primary',
        //             permission: this.archivePermissions.hasPermissionToEdit
        //         },
        //         {
        //             name: 'delete',
        //             tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
        //             icon: 'trash-alt',
        //             customIcon: false,
        //             disabled: false,
        //             type: 'danger',
        //             permission: this.archivePermissions.hasPermissionToDestroy
        //         },
        //     ]
        // }
    },
    methods: {
        ...mapActions({
            deleteWorkspace: 'archive/workspacesForm/remove',
        }),
        onView(data) {
            this.$router.push({
                name: 'subfolder',
                params: {
                    id: data.item.documentLocationRealName,
                    workspace: data.item
                }
            });
        },
        onEdit(data) {
            this.$emit('editWorkspace', data);
        },
        async onDelete(data) {
            await this.deleteWorkspace(data.item.realName);
            this.$emit('updateWorkspaces');
        },
    },
};
</script>
